<template>
  <div class="custom-mdl-view" v-if="isShow">
    <div class="mdl-overlay"></div>
    <div class="custom-modal">
      <div class="mdl-outer-blk">
        <!-- <div class="mdl-title">
          <h2>How To Play And Rules?</h2>
          <span class="icon-btn close-btn" @click="handleClose">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
              />
            </svg>
          </span>
        </div> -->

        <div class="mdl-content-blk">
          <p>
            {{ msg }}
          </p>
          <div class="elm-title">
            <span @click="handleSend">Send SMS</span>
            <span style="margin-right: 0.5em" @click="handleClose">Cancel</span>
          </div>
          <!-- <ol class="point-list">
            <li v-for="(item, i) in items" :key="i" v-html="item"></li>
          </ol> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["isShow", "link","msg"],
  methods: {
    handleClose() {
      this.$emit("closeModal", false);
    },
    handleSend() {
      this.$emit("handleSend", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-mdl-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 16px;
  z-index: 2;

  .mdl-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 16px;
    z-index: 3;
    background: #000000a8;
  }

  .custom-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
    padding: 16px;
    .mdl-outer-blk {
      width: 100%;
      height: auto;
      background: #fff;
      border-radius: 16px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 10px 5px #0000004d;
      max-height: 100%;
    }

    .mdl-title {
      width: 100%;
      display: flex;
      padding: 16px;
      position: relative;
      background: #fff;
      border-radius: 16px 16px 0 0;
      border-bottom: 1px solid #d7d7d7;
      h2 {
        font-size: 1.5em;
        font-weight: bold;
        color: #000;
      }
      .close-btn {
        position: absolute;
        left: auto;
        right: 16px;
        top: 80%;
        transform: translateY(-50%);
        background: #daa71d;
        border-radius: 100px;
        cursor: pointer;
        svg {
          fill: #fff;
          width: 24px;
          height: auto;
        }
      }
    }

    .mdl-content-blk {
      width: 100%;
      height: auto;
      max-height: calc(80vh - 64px);
      padding: 16px;
      overflow-y: auto;
      position: relative;

      p {
        padding-bottom: 1.5em;
        color: #666;
        font-size: 0.95em;
      }
    }

    .point-list {
      display: block;
      padding: 0px 0px 0px 24px;

      li {
        width: 100%;
        position: relative;
        font-size: 0.875em;
        color: #505050;
        margin: 8px 0;
        letter-spacing: normal;
        line-height: 1.2;
        display: list-item;
        &::marker {
          font-size: 1.125em;
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
}
.elm-title {
  span {
    background: #251f39;
    padding: 0.8em 1.3em;
    float: right;
    border-radius: 50px;
    color: #daa71d;
    font-weight: 500;
    font-size: 0.95em;
  }
}
</style>
