<template>
  <div class="detail-cnt">
    <header class="inner-head">
      <span class="icon-Btn" @click="$router.go(-1)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="16"
          viewBox="0 0 18 16"
        >
          <path
            d="M7.993 14.119c.395.432.4 1.127.007 1.558-.39.429-1.019.434-1.418-.004L.297 8.779c-.393-.43-.4-1.122 0-1.56L6.582.326C6.975-.105 7.607-.11 8 .32c.39.428.392 1.121-.007 1.559L3.414 6.902h13.584C17.55 6.902 18 7.39 18 8c0 .606-.452 1.097-1.002 1.097H3.414l4.58 5.023z"
          ></path>
        </svg>
      </span>

      <h2>Task Details</h2>
      <span class="icon-btn share-btn margin-left" @click="shareTask">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z"
          />
        </svg>
      </span>
    </header>

    <section class="mid-cnt">
      <div class="dtl-postbox">
        <!-- <h1>Post TikTok videos for Mall91</h1>
        <div class="info-box">
          <h2>Earning:</h2>
          <p>Rs. 50 per task completed</p>
        </div>

        <div class="info-box">
          <h2>Total Money Earned:</h2>
          <p>Rs. 12,000</p>
        </div>

        <div class="info-box">
          <h2>Total Available Tasks:</h2>
          <p>4000</p>
        </div>

        <div class="info-box">
          <h2>Total Completed Tasks:</h2>
          <p>1290</p>
        </div> -->

        <div class="btn-blk task-btn-blk">
          <button class="button task-btn" @click="handleTask">
            Go to Task
          </button>
        </div>
      </div>

      <div class="how-workbox">
        <h3>How to complete the task?</h3>
        <p v-html="replaceText(taskDetails.description)"></p>
        <!-- <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>

        <ol>
          <li>
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </li>
          <li>
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </li>
        </ol> -->
      </div>
      <div class="section-eml-blk network-blk">
        <div class="elm-title">
          <h2>Network Rovi's Earning</h2>
        </div>

        <div class="data-not-found" v-if="!networkEarnsDetails.status">
          {{ networkEarnsDetails.Message }}
        </div>

        <div class="network-table-blk" v-if="networkEarnsDetails.status">
          <div class="network-th">
            <span class="th_data">Level</span>
            <span class="th_data">Earning</span>
          </div>

          <div
            class="network-tr"
            v-for="(networkEarnsDetail, index) in networkEarnsDetails.data"
            :key="index"
          >
            <span class="td_data">Level {{ networkEarnsDetail.level }}</span>

            <span class="td_data txt-amt">{{ networkEarnsDetail.amount }}</span>
          </div>
        </div>
      </div>

      <div class="task-submit" v-if="elmStatus">
        <h2>Video</h2>
        <div class="video-box">
          <iframe
            src="https://www.youtube.com/embed/z15rH0BEgnQ?controls=0&amp;start=7"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div
          class="btn-blk completed-btn-blk"
          @click="handleMarkComplete(taskDetails.id)"
        >
          <button class="button completed-btn">Complete Task</button>
        </div>
      </div>
    </section>
    <loader-wrapper v-if="isLoading" />
    <send-modal
      :isShow="isShow"
      @closeModal="closeModal"
      @handleSend="handleSend"
      :link="link"
      :msg="secondaryMessage"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import ApiRequest from "../services/ApiRequest";
import { SessionHelper } from "../services/Session";
import { BASE_API } from "../utils/constants";
import LoaderWrapper from "../components/commonUI/LoaderWrapper.vue";
import { GET } from "../utils/HttpStatusCodeConst";
import SendModal from "../components/SendModal.vue";
export default {
  name: "TaskDetail",
  computed: {
    ...mapState(["taskDetails"]),
  },
  components: {
    LoaderWrapper,
    SendModal,
  },
  data() {
    return {
      isLoading: false,
      link: "",
      elmStatus: false,
      networkEarnsDetails: [],
      isShow: false,
      secondaryMessage:"",
    };
  },
  methods: {
    closeModal(payload) {
      this.isShow = payload;
    },
    handleSend() {
      console.log(this.link);
      try {
        JSBridgePlugin.genericIntentLaunch(this.link);
        this.isShow = false;
      } catch (e) {
        this.isShow = false;
        console.log("error occurered while launching intent", e);
      }
    },
    async shareTask() {
      console.log("share");
      console.log(this.taskDetails);
      this.isLoading = true;
      ApiRequest("https://m91.co/link", "", "post", {
        linkType: "miniapp",
        data: {
          id: this.taskDetails.id,
          name: "hahaha",
          iconImageURL: "",
          applicationURL: this.taskDetails.link,
          toOpenInMall91Tab: false,
          miniapp: true,
        },
      })
        .then((d) => {
          this.isLoading = false;
          try {
            JSBridgePlugin.showToast(
              `Complete tasks on Task91 and earn money. Also get Earning from 15 Level Network. Complete the task now \n\n https://m91.co/${d.short_code}`,
              null,
              false
            );
          } catch (e) {
            console.log("js brdge plugin not found");
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.$swal({ text: e.message, icon: "error" });
        });
    },
    replaceText(txt) {
      return txt.replaceAll("\n", "<br />");
    },
    handleTask() {
      this.isLoading = true;
      ApiRequest(BASE_API, `/partner91/lead/create`, "post", {
        partner_task_id: this.taskDetails.id,
        gaid: this.$cookies.get("G_AD_ID"),
      })
        .then((_data) => {
          this.isLoading = false;
          this.link = _data.result.data.link;
          this.secondaryMessage = _data.result.data.secondry_message;

          if (this.taskDetails.category == "my_family_task") {
            this.isShow = true;
          } else {
            try {
              window.JSBridgePlugin.openInBrowser(_data.result.data.link);
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
          // this.isLoading = !this.isLoading;
          this.$swal({ text: this.$t("_pleaseTryAgain"), icon: "error" });
        });
    },
    async handleMarkComplete(id) {
      try {
        const { data } = await Axios.put(
          BASE_API + "/partner91/task/complete",
          { task_id: id },
          {
            headers: {
              "X-Auth-Id": SessionHelper.getCookie(X_AUTH_ID),
            },
          }
        );
        if (data.result.status) {
          // this.myTasksList = data.data;
          this.getMyTasks();
        }
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },

    getNetworkDetails() {
      this.isLoading = true;
      ApiRequest(
        BASE_API,
        `/partner91/rovi/distribution/${this.taskDetails.id}`,
        GET
      )
        .then((response) => {
          this.networkEarnsDetails = response;
          console.log("networkEarnsDetail>>>>>>>>", this.networkEarnsDetails);

          // const { payment, status } = response;
          // this.setPartnerTasks(response.data);
          // this.setPartnerStatus({ payment, status });
          this.isLoading = false;
          //redirect on partner validation
        })
        .catch((error) => {
          console.log("error:", error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getNetworkDetails();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/task-detail.scss";
.margin-left {
  margin-left: auto;
  svg {
    height: 22px;
    width: 22px;
  }
}
</style>
